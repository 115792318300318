
export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcImg: require('./images/pc/banner1.jpg'),
            //             phoneImg: require('./images/mobile/banner1.jpg'),
            //             descList: [
            //                 {
            //                     descName: '3D实时地图',
            //                     sort: 1
            //                 }, {
            //                     descName: '3D实时地图',
            //                     sort: 2
            //                 }, {
            //                     descName: '七腾机器人为客户提供高精度3D视觉定位方案，通过激光3D SLAM技术进行实时地图建模，将巡检厂区内的物体、场景和数据进行映射呈现，可搭建高精度三维点云地图，且不易受环境影响，更加清晰、直观、全方位的实现巡检监测。',
            //                     sort: 3
            //                 }, 
            //             ],
            //              type: 'image',
            //             sort: 1
            //         },
            //         {
            //             pcImg: require('./images/pc/banner2.jpg'),
            //             phoneImg: require('./images/mobile/banner2.jpg'),
            //             descList: [ {
            //                     descName: '更直观的视觉效果',
            //                     sort: 1
            //                 },{
            //                     descName: '借助3D可视化技术提供更直观、逼真的视觉效果，用户可以从不同的角度观察物体或场景，获得更全面的信息，可以帮助用户更准确地理解和认知。',
            //                     sort: 1
            //                 }],
            //              type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcImg: require('./images/pc/banner3.jpg'),
            //             phoneImg: require('./images/mobile/banner3.jpg'),
            //             descList: [
            //                 {
            //                     descName: '数据可视化监测',
            //                     sort: 1
            //                 }, {
            //                     descName: '通过厂区运行设备的可视化数据与云端系统的连接，实现各项数据信息的监测管理，基于数据模型为管理者提供全面的数据分析，提高信息查询、处理和交互的及时性和有效性。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcImg: require('./images/pc/banner4.jpg'),
            //             phoneImg: require('./images/mobile/banner4.jpg'),
            //             descList: [
            //                 {
            //                     descName: '用户交互体验提升',
            //                     sort: 1
            //                 }, {
            //                     descName: '通过3D实时场景优化用户交互体验，让用户获得更加生动直观的展示效果，巡检线路和巡检点位的立体呈现，清晰有效的体现巡检效果和功能，提高用户对产品的认知度和满意度。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 4
            //         },
            //     ],
            elmentList: []
        }
    },
    created() {
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
        });
    },
    methods: {
        retention() {
            this.$router.push('/reservation');
        },
        openPdf() {
             this.$router.push('/pdfView')
        },

    }
}